import { template as template_5a53a56e363c4dc799204b8d11b4f68c } from "@ember/template-compiler";
const WelcomeHeader = template_5a53a56e363c4dc799204b8d11b4f68c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
